<template>
    <el-dialog :visible.sync="dialogPID" width="1000px" custom-class="customDialog" @close="closeDialogPID">
        <template slot="title">
            <div class="pid-tab">
                <div class="tab-item" :class="tabCurrent === 'tb' ? 'current' : ''" @click="toggleTab('tb')">淘宝PID</div>
                <div class="tab-item" :class="tabCurrent === 'pdd' ? 'current' : ''" @click="toggleTab('pdd')">拼多多PID</div>
                <div class="tab-item" :class="tabCurrent === 'jd' ? 'current' : ''" @click="toggleTab('jd')">京东PID</div>
            </div>
        </template>
        <PIDTao ref="PIDTao" v-if="tabCurrent === 'tb'" />
        <PIDPdd ref="PIDPdd" v-if="tabCurrent === 'pdd'" />
        <PIDJd ref="PIDJd" v-if="tabCurrent === 'jd'" />
    </el-dialog>
</template>

<script>
    import PIDTao from '@/components/creativecentermodule/PIDTao'
    import PIDPdd from '@/components/creativecentermodule/PIDPdd'
    import PIDJd from '@/components/creativecentermodule/PIDJd'
    export default {
        components: {
            PIDTao,
            PIDPdd,
            PIDJd
        },
        data() {
            return {
                tabCurrent: 'tb',
                dialogPID: false,
            }
        },
        methods: {
            toggleTab(val) {
                this.tabCurrent = val
            },
            // 关闭pid弹窗
            closeDialogPID() {
                if (this.tabCurrent === 'tb') {
                    this.$refs.PIDTao.clearForm()
                }
                if (this.tabCurrent === 'pdd') {
                    this.$refs.PIDPdd.clearForm()
                }
                if (this.tabCurrent === 'jd') {
                    this.$refs.PIDJd.clearForm()
                }
            },
        }
    }
</script>

<style scoped lang="scss">
    .pid-tab {
        display: flex;
        .tab-item {
            font-size: 16px;
            line-height: 20px;
            padding: 0 20px;
            cursor: pointer;
            transition: all .3s;
            &.current {
                color: #FE4800;
            }
            &:first-child {
                padding-left: 0;
            }
        }
    }
</style>