<template>
    <div class="pid-container">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="130px" size="medium">
            <el-form-item label="渠道名称" prop="name">
                <el-input v-model="ruleForm.name" style="width: 260px;"></el-input>
            </el-form-item>
            <el-form-item label="拼多多PID" prop="positionid">
                <el-input v-model="ruleForm.positionid" style="width: 260px;"></el-input>
                <span class="prompt-text">(示例：10011550_2325296_9002527，或输入商品推广链接可自动获取）</span>
            </el-form-item>
            <el-form-item>
                <el-button class="btn-orange" size="medium" @click="submitForm('ruleForm')">添加渠道</el-button>
                <router-link to="/student/productPromotion/PIDPdd" target="_blank" style="color: #FE4800; margin-left: 16px">点击查看教程？</router-link>
            </el-form-item>
        </el-form>
        <el-table :data="PIDList" style="width: 100%; margin-top: 35px;" size="mini" max-height="200px"
                  :header-cell-style="{fontWeight: 'normal', color: '#333', background: '#EFEEFF'}"
                  :cell-style="{fontSize: '12px',color: '#333'}">
            <el-table-column prop="name" label="渠道名称" align="center"></el-table-column>
            <el-table-column prop="owner_id" label="owner-id" min-width="200px"></el-table-column>
            <el-table-column prop="positionid" label="PID" align="center" min-width="200px"></el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-link :underline="false" class="link-blue" @click="editBtn(scope.row)">修改</el-link>
                    <el-link :underline="false" type="danger" @click="delBtn(scope.row.id)">删除</el-link>
                </template>
            </el-table-column>
        </el-table>
        <div class="yellow-prompt">PID务必正确，否则您得不到任何佣金哦！</div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                ruleForm: {
                    id: null,
                    name: '',
                    positionid: '',
                },
                rules: {
                    name: [
                        { required: true, message: '请输入渠道名称', trigger: 'blur' },
                        { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
                    ],
                    positionid: [
                        { required: true, message: '请输入拼多多PID', trigger: 'blur' },
                    ],
                },
                PIDList: []
            }
        },
        mounted() {
            this.getPIDList()
        },
        methods: {
            getPIDList() {
                let params = {
                    mode: 'pdd'
                }
                this.$httpStudent.axiosGetBy(this.$api.setList, params, (res) => {
                    if (res.code === 200) {
                        this.PIDList = res.data
                    } else {
                        this.$message.warning(res.message)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let formData = new FormData()
                        formData.append('mode', 'pdd')
                        formData.append('name', this.ruleForm.name)
                        formData.append('positionid', this.ruleForm.positionid)
                        if (this.ruleForm.id) {
                            formData.append('id', this.ruleForm.id)
                        }
                        this.$httpStudent.axiosPost(this.$api.setSave, formData, (res) => {
                            if (res.code === 200) {
                                this.$message.success(res.message)
                                this.clearForm()
                                this.getPIDList()
                            } else {
                                this.$message.warning(res.message)
                            }
                        }, (err) => {
                            console.log(err)
                        })
                    } else {
                        return false;
                    }
                });
            },
            editBtn(row) {
                this.ruleForm = JSON.parse(JSON.stringify(row))
            },
            delBtn(id) {
                this.$confirm('是否删除该PID，删除之后将无法恢复', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    confirmButtonClass: 'blue-btn',
                    type: 'warning',
                }).then(() => {
                    this.$httpStudent.axiosGetBy(this.$api.setDel, {id: id}, (res) => {
                        if (res.code === 200) {
                            this.$message.success(res.message)
                            this.getPIDList()
                        } else {
                            this.$message.warning(res.message)
                        }
                    }, (err) => {
                        console.log(err)
                    })
                }).catch(() => {
                    this.$message.info('已取消删除')
                });
            },
            clearForm() {
                this.$refs['ruleForm'].resetFields();
                this.ruleForm = {
                    id: null,
                    name: '',
                    positionid: '',
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .pid-container {
        padding: 30px 20px 90px;
        position: relative;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 20px;
            right: 20px;
            height: 1px;
            background: #ddd;
        }
    }
    .prompt-text {
        margin-left: 10px;
        color: #666;
    }
    .el-link + .el-link {
        margin-left: 10px;
    }
    .yellow-prompt {
        margin-top: 1px;
        color: #666;
        font-size: 12px;
        background: #FCF8E3;
        text-align: center;
        padding: 10px 0;
    }
</style>